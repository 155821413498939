import React from 'react';
import { graphql, Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { Social } from '@/components/Social';
import { Button } from '@/components/Button';
import Layout, { Main } from '../../components/Layout';
import { ProjectThumbnailsGrid } from '../../components/ProjectThumbnailsGrid';
import { ProjectThumbnail } from '../../components/ProjectThumbnail';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { Seo } from '../../components/SEO';
import { MdContent } from '../../components/MdContent';
import { CallToAction } from '../../components/CallToAction';
import { StyledProjects } from './styles';

interface IProjectsPageTemplate {
  // eslint-disable-next-line react/no-unused-prop-types
  content?: any;
  projects: any;
}

interface IProjectsPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulPage: any;
    allContentfulProject: any;
  };
  location: object;
}

const ProjectsPageTemplate = ({ content, projects }: IProjectsPageTemplate) => {
  const { title, subtitle, body, ctaTitle, ctaHookId, ctaIcon, ctaBody } =
    content;
  const { body: mdBody } = body;

  return (
    <>
      <Content restrictWidth setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
        <Text type="subtitle1" tag="h3" color="white_1_45">
          {subtitle}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={mdBody} />
        </Text>
      </Content>

      <Content
        restrictWidth
        setMaxWidth="90%"
        disableTextStyles
        enableFlex
        className="last"
      >
        <ProjectThumbnailsGrid>
          {projects.edges.map(({ node }) => {
            const {
              slug,
              thumbnailImage: nodeThumbnailImage,
              thumbnailTitle,
            } = node;
            const thumbnailImage = getImage(nodeThumbnailImage);
            return (
              <ProjectThumbnail
                key={uuidv4()}
                title={thumbnailTitle}
                image={thumbnailImage}
                alt={nodeThumbnailImage.description}
                linkTo={slug}
              />
            );
          })}
        </ProjectThumbnailsGrid>
      </Content>

      <Content restrictWidth shouldCenter>
        <Link to="/#projects">
          <Button size="large" disabled={false}>
            Back to Home
          </Button>
        </Link>
      </Content>

      <CallToAction
        hookId={ctaHookId}
        icon={ctaIcon}
        title={ctaTitle}
        body={ctaBody.ctaBody}
      />
      <Social />
    </>
  );
};

const ProjectsPageDesktopTemplate = ({ projects }: IProjectsPageTemplate) => {
  return (
    <div className="body">
      <Content
        restrictWidth
        setMaxWidth="100%"
        disableTextStyles
        enableFlex
        noMargin
      >
        <ProjectThumbnailsGrid>
          {projects.edges.map(({ node }) => {
            const {
              slug,
              thumbnailImage: nodeThumbnailImage,
              thumbnailTitle,
            } = node;
            const thumbnailImage = getImage(nodeThumbnailImage);
            return (
              <ProjectThumbnail
                key={uuidv4()}
                title={thumbnailTitle}
                image={thumbnailImage}
                alt={nodeThumbnailImage.description}
                linkTo={slug}
              />
            );
          })}
        </ProjectThumbnailsGrid>
      </Content>

      <Content restrictWidth shouldCenter>
        <Link to="/#projects">
          <Button size="large" disabled={false}>
            Back to Home
          </Button>
        </Link>
      </Content>
    </div>
  );
};

const ProjectsPage = ({ data, location }: IProjectsPage) => {
  const allProjects = get(data, `allContentfulProject`);
  const page = get(data, `contentfulPage`);
  const {
    title,
    subtitle,
    body,
    slug,
    metaDescription,
    metaTitle,
    templateKey,
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;

  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);
  return (
    <StyledProjects>
      <Layout
        location={location}
        coverImage={hero}
        title={title}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content restrictWidth>
            <Text type="title1" tag="h2" color="white_1">
              {title}
            </Text>
            <Text type="subtitle1" tag="h3" color="white_1_45">
              {subtitle}
            </Text>
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={body.body} />
            </Text>
          </Content>
        }
        desktopBody={<ProjectsPageDesktopTemplate projects={allProjects} />}
        desktopCta={
          <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={hero}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <ProjectsPageTemplate
            content={{
              title,
              subtitle,
              body,
              ctaTitle,
              ctaHookId,
              ctaIcon,
              ctaBody,
            }}
            projects={allProjects}
          />
        </Main>
      </Layout>
    </StyledProjects>
  );
};

export default ProjectsPage;

export const ProjectsPageQuery = graphql`
  query ProjectsPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      body {
        body
      }
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      metaTitle
      metaDescription
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulProject {
      edges {
        node {
          slug
          thumbnailImage {
            description
            gatsbyImageData(
              width: 225
              height: 120
              quality: 85
              placeholder: BLURRED
            )
          }
          thumbnailTitle
        }
      }
    }
  }
`;
